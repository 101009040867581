import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { SearchField, ButtonPrimary } from 'components/Shared';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FormField = styled.div`
  max-width: 400px;
`;

const IndexPage: React.FC = () => {
  const [slug, setSlug] = useState<string>('');
  const handleOpen = () => {
    const url = `${window.location.protocol}//${window.location.host}/${slug}?preview=1`;
    window.open(url, '_blank');
  };

  useEffect(() => {
    const onEnter = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        handleOpen();
      }
    };

    document.addEventListener('keypress', onEnter);

    return () => document.removeEventListener('keypress', onEnter);
  }, [slug]);

  return (
    <Container>
      <FormField>
        <h4>Visit in preview</h4>
        <p>
          Enter the slug of the product (e.g. <i>conflictfood-ingwer</i>) to
          visit a page in preview without sending analytics events.
        </p>
        <SearchField
          placeholder="e.g. conflictfood-ingwer"
          onChange={setSlug}
          value={slug}
          autofocus
          name="slug"
        />
        <ButtonPrimary onClick={handleOpen}>Open</ButtonPrimary>
      </FormField>
    </Container>
  );
};

export default IndexPage;
